import React from "react";
import "./../ThreeSteps/ThreeStepsStylr.css";
import time from "./../../../Assets/time.png";
import cash from "./../../../Assets/cash.png";
import hands from "./../../../Assets/hands.png";
import house from "./../../../Assets/house.png";

function ThreeSteps() {
  return (
    <div className="steps_pic_main_div ">
      <div className="satisfy_left">
        <div className="easy_heading">Three Easy Steps</div>
        <div className="icons_and_text ">
          <div>
            {" "}
            <img className="three_icons" src={time} />{" "}
          </div>

          <div className="steps_border">
            {" "}
            <div className="icons_headings">
              Schedule a Free Home Consultation
            </div>
            <p className="below_icons_headings">
            We will learn about your needs and assess your 
home by walking through the property with you 
explaining the process as we go.
            </p>
          </div>
        </div>

        <div className="icons_and_text ">
          <div>
            {" "}
            <img className="three_icons" src={cash} />{" "}
          </div>
          <div>
            {" "}
            <div className="icons_headings">Get A Cash Offer Today</div>
            <p className="below_icons_headings">
            After our property analysis is complete, we will send you a firm offer based on your wants and  
needs. We make this stress-free.
            </p>
          </div>
        </div>

        <div className="icons_and_text ">
          <div>
            {" "}
            <img className="three_icons" src={hands} />{" "}
          </div>
          <div>
            <div className="icons_headings">Close At Your Convenience</div>
            <p className="below_icons_headings">
            Your property will be purchased with cash, so 
you can close on your schedule.
            </p>
          </div>
        </div>
      </div>

      <div className="satisfy_right">
        {" "}
        <img className="house_img" src={house} />
      </div>
    </div>
  );
}

export default ThreeSteps;
