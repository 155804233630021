import React from 'react'
import "./../LightSchedule/LightScheduleStyle.css"
import dark_phone from "./../../Assets/dark_phone.png"

function LightSchedule() {
  return (
    <div className='outer_color_div'>
    <div className='inner_colored_div'>

    <div className='consultation_div' >
    <div className='consultation_text'>Schedule A Free Consultation Today</div>
    <div className='below_consultation_text'>We can close in less than 72 hours if needed</div>
    <div className='coloured_button'>Get My Offer Now!</div>
    </div>

    <div className='phone_no_div'>
     <img className='light_phone_img' src={dark_phone} />
     <div className='contact_num'>866-931-6501</div>

    </div>
        
    </div>
    </div>
  )
}

export default LightSchedule
