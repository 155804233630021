import React from 'react'
import "./../Questions/QuestionsStyle.css"


function Questions() {
  return (
    <div className='main_coloured_question_div'>
    <div className='div_after_main'></div>
    <div className='questions_heading'>Questions? We Have Answers.</div>

    <div className='cards_div' >

        <div className='left_column' >
        
            <div className='all_cards card_one'> 
            <div className='card_headings'>How does the process work to sell my house?</div>
            <p className='paragraphss'>Once you have completed the “Get My Offer Today” 
Form, one of our real estate solution specialists will
 contact you shortly (usually within a business day). 
We will need to gather additional information on the 
property, previous work on the property, additions, 
etc. Your home may qualify for our special program 
that allows us to buy your home directly from 
you right over the phone.</p>       
             </div>

            <div className='all_cards card_two'> 
            <div className='card_headings'>Are you REALTORS™?</div>

            <p className='paragraphss'>No. Home Offer Direct is a real estate investment and solutions company. There is never a charge or a commission when we buy your property! However, if listing your property is the best solution then will connect you with a recommended licensed realtor.</p>
             </div>

            <div className='all_cards card_three'>             
            <div className='card_headings'>Is my information kept confidential?</div>

            <p className='paragraphss' >ABSOLUTELY 100%! Your privacy is of the utmost importance to us. Any information you provide is completely confidential! If you want to deal with a reliable, reputable company who will treat you with professionalism, understanding, and respect – YOU HAVE COME TO THE RIGHT PLACE
</p>
            
             </div>

            

        </div>
        <div className='right_column' >
        
        <div className='all_cards card_four'>
        <div className='card_headings'>What sort of houses do you buy?</div>
         <p className='paragraphss' >We buy houses in any condition, in any area, in any price range, in any situation! We will buy your house as-is, you don’t need to do ANY repairs!</p> </div>

        <div className='all_cards card_five'>
        <div className='card_headings'> What if I am behind on my payments, in foreclosure or bankruptcy? Are you still able help? </div>
         <p className='paragraphss'>YES! Home Offer Direct is a professional real estate solutions company with years of experience in solving these types of difficult situations. Please contact us for a confidential consultation.  </p></div>


        <div className='all_cards card_six'> 
        <div className='card_headings'>What do you mean “any condition, area, price range, or situation”?</div>
         <p className='paragraphss' >Whether your house is in foreclosure, over-leveraged, condemned, has liens or health department violations, not maintained, fire-damaged, or about to fall down WE CAN BUY IT! </p> </div>

        <div className='all_cards card_seven'>
        <div className='card_headings'>What will your service cost me?</div>

         <p className='paragraphss' > Nothing! Inquire today for your free home consolation.</p>  </div>

        </div>


    </div>
      
    </div>
  )
}

export default Questions
