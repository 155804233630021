import React from "react";
import "./../Testimonial/Testimonialstyle.css";
import card from "./../../Assets/Group 215.png";
import dots from "./../../Assets/dots.png";

import Slider from "react-slick";
function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  return (
    <>
      <>
        
        <Slider {...settings}>
          <div className="abc">
            <div className="main_testimonial_div">
              <div className="testimonial_heading">What Our Clients Say</div>
              <div className="testiminoal_img_div">
                {" "}
                <img className="testimonial_img" src={card} />
              </div>
              {/* <div className='dots_img_div'> <img className='dots_img' src={dots} /></div> */}
            </div>
          </div>


          <div className="main_testimonial_div">
            <div className="testimonial_heading">What Our Clients Say</div>
            <div className="testiminoal_img_div ttt">
              {" "}
              <img className="testimonial_img" src={card} />
            </div>
            {/* <div className='dots_img_div'> <img className='dots_img' src={dots} /></div> */}
          </div>
        </Slider>
      </>
    </>
  );
}

export default Testimonial;
