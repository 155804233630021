import React from "react";
import AmericaBuyer from "../Navbar/AmericaBuyer/AmericaBuyer";
import ThreeSteps from "../Navbar/ThreeSteps/ThreeSteps";
import Comparison from "../Comparison/Comparison";
import DarkSchedule from "../DarkSchedule/DarkSchedule";
import Testimonial from "../Testimonial/Testimonial";
import Questions from "../Questions/Questions";
import LightSchedule from "../LightSchedule/LightSchedule";
import Navbar from "../Navbar/Navbar";

function Home() {
  return (
    <div>
      <Navbar />
      <AmericaBuyer />
      <div id="id_steps">
        {" "}
        <ThreeSteps />
      </div>
      <div id="id_comparison"></div> <Comparison />
      <DarkSchedule />
      <div id="id_testimonial"></div> <Testimonial />
      <div id="id_questions"></div> <Questions />
      <LightSchedule />
    </div>
  );
}

export default Home;
