import React from 'react'
import "./../Comparison/ComparisonStyle.css"
import logo from "./../../Assets/all_state_logo.png"
import group_214 from "./../../Assets/Group 214.png"
import group_213 from "./../../Assets/Group 213.png"
import group_212 from "./../../Assets/Group 212.png"
import group_211 from "./../../Assets/Group 211.png"
import group_210 from "./../../Assets/Group 210.png"
import group_209 from "./../../Assets/Group 209.png"
import group_208 from "./../../Assets/Group 208.png"
import group_207 from "./../../Assets/Group 207.png"
import group_206 from "./../../Assets/Group 206.png"








function Comparison() {
  return (
    <div className='comparison_main_outer '>
    <div className='comparison_inner_main'>
    <div className='row'>


    <div className='col-3'>
        <div>
        <div className='img_logo_div'> <img src={logo} className='img_of_logo' /> </div>
        <div className='comparison_left'> None</div>
        <div className='comparison_left'> NO Closing Costs</div>
        <div className='comparison_left'> NONE </div>
        <div className='comparison_left'> NONE We make cash offers</div>
        <div className='comparison_left'> Immediate cash offer</div>
        <div className='comparison_left'> 1 (Just Us)</div>
        <div className='comparison_left'> Close on your time frame</div>
        <div className='comparison_left'> We Repairs needed</div>




        </div>
    </div>


    <div className='col-6'>
     <div>
     <div className='comparison_heading'>Selling With Us<br/>VS. <br/>Listing With A Realtor</div>
     <div className='capsule_div'> <img className='capsule_img' src={group_206} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_207} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_208} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_209} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_210} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_211} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_212} /></div>
     <div className='capsule_div'> <img className='capsule_img' src={group_213} /></div>




     </div>
    </div>


    <div className='col-3'>
    <div>
     
    <div className='img_logo_div'>  </div>
    <div className='comparison_right'>6% on average is paid by you.  </div>
    <div className='comparison_right'>2% on average is paid by you.  </div>
    <div className='comparison_right'>Yes, up to 15% of sales fall through  </div>
    <div className='comparison_right'>Yes, sale is often subject to appraisal  </div>
    <div className='comparison_right'>+/- 91 Days </div>
    <div className='comparison_right'>Multiple Showings </div>
    <div className='comparison_right'>30-60 days after buyers offer </div>
    <div className='comparison_right'>Paying for Repairs or Upkeep </div>


    </div>

    </div>
    </div>


    </div>

    <div  className='complete_pic_div'> <img className='complete_pic' src={group_214} />  </div>
       
     </div>
  )
}

export default Comparison
