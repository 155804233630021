import React from 'react'
import "./AmericaBuyerStyle.css"
import flag from "./../../../Assets/flag.png"
import star from "./../../../Assets/star.png"

function AmericaBuyer() {
  return (
    <div  className=' main_form_america'>
       
      <div className='left_div '>
        <div className='side_form_background '>
            <div className="form_heading">
              {" "}
              Get Your Cash Offer
              <br /> In 30 Minutes Or Less!{" "}
            </div>

            <div className="actual_form">
              <form action="#">
                <label className="my_form_labels">Name* </label>
                <br />
                <input className="my_form_inputs" type="text" required />
                <br />

                <label className="my_form_labels">Phone Number*</label>
                <br />
                <input className="my_form_inputs" type="phone" required />
                <br />

                <label className="my_form_labels">Email*</label>
                <br />
                <input className="my_form_inputs" type="email" required />
                <br />

                <label className="my_form_labels">Address*</label>
                <br />
                <input className="my_form_inputs" type="text" required />
                <br />
                <br />

                <div className="btn_class_center">
                <button className="my_form_button container" type="submit">
                  Get My Offer Now!
                </button>
                </div>
                
              </form>
            </div>
            </div>
            </div>
          

        <div className=' right_div row'>
        <div  className='america_img_div row'>
        <div className='col-2' ></div>
        <div className='main_heading_div col-10'>
            <div className='america_heading'>AMERICA’S <br/>MOST TRUSTED <br/>HOME BUYER</div>
            <div className='below_america_heading'>Schedule your free consultation today to find <br/>out how we can help you</div>

        <div className='stars_text_div' >
        <div className='inner_row_flex'>
        <div> <img className='star_img' src={star} />  </div>
        <div className='star_text'> Highest Cash Offer </div>
        </div>

        <div className='inner_row_flex'>
        <div> <img className='star_img' src={star} />  </div>
        <div className='star_text'> No Repairs Needed </div>
        </div>

        <div className='inner_row_flex'>
        <div> <img className='star_img' src={star} />  </div>
        <div className='star_text'>Purchase In Any Condition </div>
        </div>

        <div className='inner_row_flex'>
        <div> <img className='star_img' src={star} />  </div>
        <div className='star_text'> No Fees or Commission </div>
        </div>

        <div className='inner_row_flex'>
        <div> <img className='star_img' src={star} />  </div>
        <div className='star_text'> Virtual Consultations Available</div>
        </div>
          </div>    
 



            </div>
        </div>

        </div>  
 
      
    </div>
  )
}

export default AmericaBuyer
