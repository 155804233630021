import React from 'react'
import "./../DarkSchedule/DarkScheduleStyle.css"
import light_phone from "./../../Assets/light_phone.png"

function DarkSchedule() {
  return (
    <div className='outer_colored_div'>
    <div className='inner_colored_div'>

    <div className='consultation_div' >
    <div className='consultation_text'>Schedule A Free Consultation Today</div>
    <div className='below_consultation_text'>We can close in less than 72 hours if needed</div>
    <div className='coloured_buttonn'>Get My Offer Now!</div>
    </div>

    <div className='phone_no_div'>
     <img className='light_phone_img' src={light_phone} />
     <div className='contact_no'>866-931-6501</div>

    </div>
        
    </div>
    </div>
  )
}

export default DarkSchedule
